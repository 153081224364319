import { BACKEND_URL } from "../../config";

const apis = {
  /***************************** Auth **********************************/
  me: BACKEND_URL + "me",
  login: BACKEND_URL + "login",
  email_login:BACKEND_URL + "email-login",
  crm_login: BACKEND_URL + "crm-login",
  register: BACKEND_URL + "register",
  forget_password: BACKEND_URL + "forget-password",
  reset_password: BACKEND_URL + "reset-password",
  email_verified: BACKEND_URL + "email-verified",
  resend_otp: BACKEND_URL + "resend-otp",

  /***************************** Public Endpoint **********************************/
  get_public_clinic: BACKEND_URL + "get?role_id=2",
  get_public_location: BACKEND_URL + "get-locations",
  get_nearest_location: BACKEND_URL + "get-address?postal_code=",
  get_payment_gateway: BACKEND_URL + "get-payment-gateway",

  /***************************** Public Endpoint **********************************/
  upload_image: BACKEND_URL + "providers/storeImage",

  /***************************** Roles **********************************/
  get_all_roles: BACKEND_URL + "roles/get-all-roles?",
  get_role_by_id: BACKEND_URL + "roles/get-role-by-id/",
  store_role: BACKEND_URL + "roles/store-role",
  update_role: BACKEND_URL + "roles/update-roles",
  delete_role: BACKEND_URL + "roles/delete-role/",
  Referal_point:BACKEND_URL + "get-referral_setting",
  update_ReferalPoint:BACKEND_URL + "update-referral_setting",

  /***************************** Menu **********************************/
  get_all_menu: BACKEND_URL + "menu/get-all-menus",
  get_user_manual:BACKEND_URL + "user-manual/get-all",
  get_GHL_Webhook: BACKEND_URL + "ghl/get-ghl-webhook-url",
  Store_GHL:BACKEND_URL + "ghl/store-ghl-webhook-url",
  Store_Manual:BACKEND_URL + "user-manual/store-user-manual",
  store_menu: BACKEND_URL + "menu/store-menu",
  update_menu: BACKEND_URL + "menu/update-menu",
  update_ghl:BACKEND_URL + "ghl/update-ghl-webhook-url",
  update_manual:  BACKEND_URL + "user-manual/update-user-manual",     
  delete_menu: BACKEND_URL + "menu/delete-menu/",
  delete_ghl:BACKEND_URL + "ghl/delete-ghl-webhook-url/",
  delete_Manual:BACKEND_URL + "user-manual/delete/",
  get_menu_by_id: BACKEND_URL + "menu/get-menu-by-id/",
  get_manual_id:BACKEND_URL + "user-manual/get-user-manual-by-id/",
  get_ghl_by_id:BACKEND_URL + "ghl/get-ghl-webhook-url-by-id/",

  /***************************** Permission **********************************/
  get_all_permission: BACKEND_URL + "permission/get-all-permissions?",
  store_permission: BACKEND_URL + "permission/store",
  get_permission_by_id: BACKEND_URL + "permission/get-permission-by-id/",
  update_permission: BACKEND_URL + "permission/update-permission",
  delete_permission: BACKEND_URL + "permission/delete-permission/",

  /***************************** Languages **********************************/
  get_all_languages: BACKEND_URL + "languages/get-all-languages?",
  store_languages: BACKEND_URL + "languages/store-language",
  update_languages: BACKEND_URL + "languages/update-language",
  delete_languages: BACKEND_URL + "languages/delete-language/",
  get_language_by_id: BACKEND_URL + "languages/get-language-by-id/",

  /***************************** Payment Methods **********************************/
  get_all_payment_methods: BACKEND_URL + "payment-method/get-all-payment-method?page=1&limit=100",
  store_payment_methods: BACKEND_URL + "payment-method/store-payment-method",
  update_payment_methods: BACKEND_URL + "payment-method/update-payment-method",
  delete_payment_methods: BACKEND_URL + "payment-method/delete-payment-method/",
  get_payment_methods_by_id: BACKEND_URL + "payment-method/get-payment-method-by-id/",

  /***************************** Clinics **********************************/
  get_all_clinics: BACKEND_URL + "providers/get-all-clinics",
  store_clinics: BACKEND_URL + "providers/store-clinic",
  update_clinics: BACKEND_URL + "providers/update-clinic",
  delete_clinics: BACKEND_URL + "providers/delete-clinic/",
  get_clinic_by_id: BACKEND_URL + "providers/get-clinic-by-id/",
  clinics_change_password: BACKEND_URL + "providers/change-password",
  update_clinics_business_info: BACKEND_URL + "providers/business-info",
  clinics_social_info: BACKEND_URL + "providers/social-info",
  clinics_twilio_configuration: BACKEND_URL + "providers/twillo-configuration",
  clinics_radius: BACKEND_URL + "providers/radius",
  clinics_calender: BACKEND_URL + "providers/calender-setting",
  contact_details: BACKEND_URL + "providers/contact-detail",
  clinic_booking_setting: BACKEND_URL + "providers/booking-setting",

  /***************************** Clinic Location **********************************/
  store_clinics_location: BACKEND_URL + "location/store",
  get_clinics_locations: BACKEND_URL + "location/get-all-locations",
  delete_clinics_location: BACKEND_URL + "location/delete-location/",
  update_clinics_location: BACKEND_URL + "location/update-location",
  get_clinics_location_by_id: BACKEND_URL + "location/get-location-by-id/",


  /***************************** Staff Hour and holidays **********************************/
  store_staff_schedule: BACKEND_URL + "staffSchedule/staff-schedule",
  get_staff_schedule: BACKEND_URL + "staffSchedule/get-staff-schedule?staff_id=",
  store_staff_holiday: BACKEND_URL + "staffHoliday/staff-holiday",
  get_staff_holiday: BACKEND_URL + "staffHoliday/get-staff-holiday?provider_id=",
  delete_staff_holiday: BACKEND_URL + "staffHoliday/staff-holiday-delete",
  store_staff_break: BACKEND_URL + "staffBreak/staff-break",
  get_staff_break: BACKEND_URL + "staffBreak/get-staff-break",
  delete_staff_break: BACKEND_URL + "staffBreak/staff-break-delete",


  /***************************** Clinic Business Hour and holidays **********************************/
  store_clinics_business_hours: BACKEND_URL + "business_hours/business_hours",
  get_clinic_business_hours: BACKEND_URL + "business_hours/get-business_hour?provider_id=",
  store_clinic_holiday: BACKEND_URL + "holiday/holiday",
  get_clinic_holiday: BACKEND_URL + "holiday/get-holiday?provider_id=",
  delete_clinic_holiday: BACKEND_URL + "holiday/delete-holiday",


  /***************************** Clinic Service Types **********************************/
  store_clinics_service_type: BACKEND_URL + "providers/service-type-store",
  get_service_type: BACKEND_URL + "providers/get-service-type",

  /***************************** Clinic Service **********************************/
  store_clinics_service: BACKEND_URL + "services/store-service",
  update_clinics_service: BACKEND_URL + "services/update-service",
  get_service_by_id: BACKEND_URL + "services/get-service-by-id/",
  get_Bundle:BACKEND_URL + "services/get-all-service-dropdown?provider_id=",
  get_clinic_services: BACKEND_URL + "services/get-service?provider_id=",
  get_clinic_extra_services: BACKEND_URL + "services/get-extra-service?provider_id=",
  get_clinic_events: BACKEND_URL + "services/get-event-services?provider_id=",

  get_service :BACKEND_URL + "services/get-all-service?provider_id=",
  delete_clinic_service: BACKEND_URL + "services/delete-service/",
  update_service:BACKEND_URL + "service/update-service-status/",
  promo_code:BACKEND_URL + "promo-code/get-promo-code",
  promo_code_create:BACKEND_URL + "promo-code/promo-code",
  gift_cards_store:BACKEND_URL + "gift-card/gift-card",
  service_by_category:BACKEND_URL+"services/get-service-by-categories",

  update_Gift_Card:BACKEND_URL + "gift-card/update-gift-card",
  gift_card_id:BACKEND_URL + "gift-card/get-gift-card/",
  get_gift_card:BACKEND_URL + "gift-card/get-gift-card",
  promo_code_id:BACKEND_URL + "promo-code/get-promo-code/",
  service_by_category:BACKEND_URL+"services/get-service-by-categories",
  update_promo:BACKEND_URL + "promo-code/update-promo-code",
  delete_Promo:BACKEND_URL + "promo-code/delete-promo-code",
  delete_gift_card:BACKEND_URL + "gift-card/delete-gift-card",

  /***************************** Clinic Plan **********************************/
  store_clinics_plan: BACKEND_URL + "plans/store-plan",
  get_clinics_plan: BACKEND_URL + "plans/get-all-plans?provider_id=",
  get_plan:BACKEND_URL + "plans/get-plans?provider_id=",
  delete_clinics_plan: BACKEND_URL + "plans/delete-plan/",
  update_clinics_plan: BACKEND_URL + "plans/update-plan", 
  get_clinics_plan_by_id: BACKEND_URL + "plans/get-plan-by-id/",
  
  /***************************** Clinic Staff **********************************/
  store_clinics_staff: BACKEND_URL + "staff/store-staff",
  update_clinics_staff: BACKEND_URL + "staff/update-staff",
  get_staff_by_id: BACKEND_URL + "staff/get-staff?",
  get_clinic_staff: BACKEND_URL + "staff/get-all-staffs?provider_id=",
  clinic_staff:BACKEND_URL + "staff/get-staffs?provider_id=",
  delete_clinic_staff: BACKEND_URL + "staff/delete-staff/",
  clinic_staff_schedule: BACKEND_URL + "staffSchedule/staff-schedule-get",

  /***************************** Clinic rooms **********************************/
  store_clinics_room: BACKEND_URL + "rooms/store-room",  
  get_clinics_rooms: BACKEND_URL + "rooms/get-all-rooms?provider_id=",
  get_rooms: BACKEND_URL + "rooms/get-rooms?provider_id=",
  delete_clinics_rooms: BACKEND_URL + "rooms/delete-room/",
  update_clinics_rooms: BACKEND_URL + "rooms/update-room",
  get_clinics_rooms_by_id: BACKEND_URL + "rooms/get-room-by-id/",

  /***************************** Clinic Treatment **********************************/
  store_clinics_treatment: BACKEND_URL + "treatments/store-treatment",
  get_clinics_treatment: BACKEND_URL + "treatments/get-all-treatment?provider_id=",
  delete_clinics_treatment: BACKEND_URL + "treatments/delete-treatment/",
  update_clinics_treatment: BACKEND_URL + "treatments/update-treatment",
  get_clinics_treatment_by_id: BACKEND_URL + "treatments/get-treatment-by-id/",

  /***************************** Clinic Treatment Form **********************************/
  store_clinics_treatment_form: BACKEND_URL + "treatment-forms/store-treatment-form",
  get_clinics_treatment_form: BACKEND_URL + "treatment-forms/get-all-treatment-forms?provider_id=",
  treatment_form: BACKEND_URL + "treatment-forms/get-treatment-forms?provider_id=",
  delete_clinics_treatment_form: BACKEND_URL + "treatment-forms/delete-treatment-form/",
  update_clinics_treatment_form: BACKEND_URL + "treatment-forms/update-treatment-form",
  get_clinics_treatment_form_by_id: BACKEND_URL + "treatment-forms/get-treatment-form-by-id/",

  /***************************** Clinic Treatment Group category  **********************************/
  store_clinics_treatment_group: BACKEND_URL + "categories/store-category",
  get_clinics_treatment_group: BACKEND_URL + "categories/get-all-categories?provider_id=",
  treatment_group: BACKEND_URL + "categories/get-categories?provider_id=",
  delete_clinics_treatment_group: BACKEND_URL + "categories/delete-category/",
  update_clinics_treatment_group: BACKEND_URL + "categories/update-category",
  get_clinics_treatment_group_by_id: BACKEND_URL + "categories/get-category-by-id/",

  /***************************** Notification Event **********************************/
  get_all_notification_event: BACKEND_URL + "notification-events/get-all-notification-event?",
  store_notification_event: BACKEND_URL + "notification-events/store-notification-event",
  update_notification_event: BACKEND_URL + "notification-events/update-notification-event",
  delete_notification_event: BACKEND_URL + "notification-events/delete-notification-event/",
  get_notification_event_by_id: BACKEND_URL + "notification-events/get-notification-event-by-id/",

  /***************************** Notification Template **********************************/
  get_all_notification_templates_events: BACKEND_URL + "notification-events/get-all-notification-event",
  update_notification_template: BACKEND_URL + "notificationTemplate/update-notification-template",
  get_notification_template_by_id: BACKEND_URL + "notificationTemplate/get-notification-template-by-id/",
  get_placeholder_value : BACKEND_URL +"get-message-placeholders",


  /***************************** Notification Template **********************************/
  user_location: BACKEND_URL + "customers/location",


  /********************************************************* User  *********************************************************************/
  profile_update: BACKEND_URL + "customers/profile-update",
  user_change_password: BACKEND_URL + "customers/change-password",

  /***************************** Customer Card **********************************/
  customer_create: BACKEND_URL + "customers/customer-create",
  customer_update: BACKEND_URL + "customers/customer-update",


  /*****************************  Card **********************************/
  create_card: BACKEND_URL + "customers/cards/create-card",
  delete_card: BACKEND_URL + "customers/cards/delete-credit-card",
  get_all_cards: BACKEND_URL + "customers/cards/get-customer-cards",
  default_card: BACKEND_URL + "customers/cards/make-default-card",



  /***************************** Super Admin  Subscription plan **********************************/
  store_subscription_plan: BACKEND_URL + "subscription-plans/store-subscription-plan",
  update_subscription_plan: BACKEND_URL + "subscription-plans/update-subscription-plan",
  subscription_plans_by_id: BACKEND_URL + "subscription-plans/get-subscription-plan-by-id/",
  all_subscription_plans: BACKEND_URL + "subscription-plans/get-all-subscription-plans",
  delete_subscription_plan: BACKEND_URL + "subscription-plans/delete-subscription-plan/",


  /***************************** User  Transaction **********************************/
  customer_transaction: BACKEND_URL + "customers/get-transaction-history",
  customer_wallet_transaction: BACKEND_URL + "customers/get-wallet-point-history",

  /***************************** User  Transaction **********************************/
  customer_notification: BACKEND_URL + "customers/notification",
  read_notification: BACKEND_URL + "customers/read-notifications",

  /***************************** User  Support **********************************/
  get_all_support: BACKEND_URL + "customers/supports",
  store_support: BACKEND_URL + "customers/supports/store-support-ticket",
  reply_support: BACKEND_URL + "support-reply",
  close_support: BACKEND_URL + "customers/supports/close-support-ticket",


  /***************************** User  Subscription plan **********************************/

  get_my_subscription: BACKEND_URL + "customers/get-my-subscription",
  get_subscription_list: BACKEND_URL + "get-subscription-plan?provider_id=",
  create_subscription: BACKEND_URL + "customers/subscriptions/create-subscription",
  cancel_subscription: BACKEND_URL + "customers/subscriptions/cancel-subscription",
  pause_subscription: BACKEND_URL + "customers/subscriptions/pause-subscription",
  update_subscription: BACKEND_URL + "customers/subscriptions/update-subscription",


  /***************************** User  Booking widget **********************************/
  get_booking_services: BACKEND_URL + "get-booking-service?group=",
  get_booking_holiday: BACKEND_URL + "provider-holidays?date=",
  get_booking_schedule: BACKEND_URL + "booking-schedule",
  find_guest: BACKEND_URL + "find-guest",
  promo_code_booking:BACKEND_URL + "check-promo",
  Gift_card_booking:BACKEND_URL + "check-gift-card",
  book_appointment: BACKEND_URL + "customers/booking",
  my_booking: BACKEND_URL + "customers/my-bookings?status=",
  my_booking_details: BACKEND_URL + "customers/get-appointment-by-id/",
  cancel_booking: BACKEND_URL + "customers/cancel-booking",
  edit_booking: BACKEND_URL + "customers/edit-booking",
  booking_status: BACKEND_URL + "get-booking-status",
  user_booking_status: BACKEND_URL + "get-user-booking-status",

  /***************************** User  plan Booking widget **********************************/
  get_booking_plans: BACKEND_URL + "get-booking-plan?slug=",
  book_plan: BACKEND_URL + "customers/book-a-plan",
  get_my_plan: BACKEND_URL + "customers/my-plans",
  treatment_plan: BACKEND_URL + "customers/my-plans",


  /***************************************************** CRM  Appointment ******************************************************/
  get_all_appointment: BACKEND_URL + "crm/appointments/get-all-appointments?status=",
  update_appointment_status: BACKEND_URL + "crm/appointments/update-appointment-status",
  appointment_details: BACKEND_URL + "crm/appointments/get-appointment-by-id/",
  appointent_calander:BACKEND_URL + "crm/appointments/get-calendar-appointment-by-id/",
  appointment_history: BACKEND_URL + "crm/appointments/user-booking-history",
  Cosent_form:BACKEND_URL + "crm/appointments/get-appointment-treatment-foam/",
  activity_log:BACKEND_URL +"activity/",


  /***************************************************** CRM Requested Appointment ******************************************************/
  get_all_requested_appointment: BACKEND_URL + "crm/appointments/get-all-requested-appointments",
  approve_appointment_status: BACKEND_URL + "crm/appointments/confirm-requested-appointments",

  /***************************************************** CRM  Staff ******************************************************/
  get_crm_staff: BACKEND_URL + "crm/staffs/get-all-staffs",

  get_staffs: BACKEND_URL + "crm/staffs/get-staffs",
  assign_staff: BACKEND_URL + "crm/appointments/assign-staff",

  remove_staff:BACKEND_URL + "crm/appointments/remove-staff",



  /***************************************************** CRM  POS ******************************************************/
  get_all_crm_booking: BACKEND_URL + "crm/pos/get-all-bookings?status=",
  get_booking_invoice: BACKEND_URL + "crm/pos/get-booking-by-id/",
  send_reminder: BACKEND_URL + "crm/pos/send-reminder",
  resend_invoice: BACKEND_URL + "crm/pos/resend-invoice",
  void_invoice: BACKEND_URL +"crm/pos/set-invoice-void",
  void_password:BACKEND_URL + "crm/pos/set-invoice-reset",
    /*****************************************************  Chat Module ******************************************************/
    chat_list:BACKEND_URL + "crm/chats/chat-user-list",
    contact_chat:BACKEND_URL +"crm/contacts",
    delete_chat:BACKEND_URL+"crm/chats/delete-chat/",
    selected_chat:BACKEND_URL + "crm/chats/selected-chat/", 
    Selected_chat_user:BACKEND_URL + "customers/chats/selected-chat/",
    Get_Messagese:BACKEND_URL + "crm/chats/get-messages?user_id=",
    Get_Messagese_user:BACKEND_URL + "customers/chats/get-messages?provider_id=",
    get_clinic_chat:BACKEND_URL + "customers/chats/get-chat-clinics",
    Read_Messages:BACKEND_URL + "crm/chats/read-chat/", 
    Send_Messages:BACKEND_URL + "crm/chats/create-message",
    Send_Messages_user:BACKEND_URL + "customers/chats/create-message",
    Read_Message_user:BACKEND_URL +"customers/chats/read-chat/",
    
  /***************************************************** Appointment Attachment ******************************************************/
  get_all_attachment: BACKEND_URL + "crm/appointments/attachments/get-all-attachments?booking_id=",
  delete_attachment: BACKEND_URL + "crm/appointments/attachments/delete-attachment/",
  store_attachment: BACKEND_URL + "crm/appointments/attachments/store-attachment",
  store_gallery: BACKEND_URL + "crm/appointments/attachments/store-gallery-attachment",
  get_all_gallery: BACKEND_URL + "crm/appointments/attachments/get-gallery-attachment/",
  delete_gallery: BACKEND_URL + "crm/appointments/attachments/delete-gallery/",
  delete_gallery_file: BACKEND_URL + "crm/appointments/attachments/delete-gallery-file/",
  store_milestone: BACKEND_URL + "crm/appointments/attachments/store-milestone",
  get_all_milestone: BACKEND_URL + "crm/appointments/attachments/get-milestone/",
  remove_milestone: BACKEND_URL + "crm/appointments/attachments/remove-milestone/",

  /***************************************************** Appointment  Tags ******************************************************/
  get_all_tags: BACKEND_URL + "crm/get-all-tags",
  store_tags: BACKEND_URL + "crm/create_tag",

  /*****************************************************   Appointment Notes ******************************************************/
  get_all_notes: BACKEND_URL + "crm/appointments/notes/get-all-notes?booking_id=",
  store_notes: BACKEND_URL + "crm/appointments/notes/store-note",

  /*****************************************************   Appointment Reminder ******************************************************/
  get_all_reminders: BACKEND_URL + "crm/appointments/reminders/get-all-reminders?booking_id=",
  store_reminder: BACKEND_URL + "crm/appointments/reminders/store-reminder",




  /***************************************************** Book  Appointment  ******************************************************/
  get_all_existing_user: BACKEND_URL + "crm/user/get-all-users?status=",
  user_existing_card: BACKEND_URL + "crm/cards/get-customer-cards/",
  delete_card:BACKEND_URL + "crm/cards/delete-credit-card",
  default_card_api: BACKEND_URL +"crm/cards/make-default-card",
  get_services_category: BACKEND_URL + "crm/service-categories/get-all-service-categories",
  get_all_plan: BACKEND_URL + "crm/service-categories/get-all-plan-services",
  appointment_schedule: BACKEND_URL + "crm/appointments/booking-schedule",
  appointment_holiday: BACKEND_URL + "crm/appointments/provider-holidays?date=",
  appointment_guest_user: BACKEND_URL + "crm/guest-user",
  create_appointment: BACKEND_URL + "crm/appointments/create-appointment",
  create_appointment_plan: BACKEND_URL + "crm/book-a-plan",
  create_giftCard: BACKEND_URL + "gift-card/gift-card-buy",
  update_appointment: BACKEND_URL + "crm/appointments/update-appointment-booking",
  checkout_appointment: BACKEND_URL + "crm/appointments/check-out",
  create_crm_card: BACKEND_URL + "crm/cards/create-card",
  appointment_by_id: BACKEND_URL + "crm/appointments/get-appointment/",
  customer_by_id: BACKEND_URL + "crm/appointments/get-customer-by-id/",
  reschedule_appointment: BACKEND_URL + "crm/appointments/update-booking",

  /***************************************************** CRM  ******************************************************/
  get_all_customers: BACKEND_URL + "crm/get-all-customers",
  get_customer_by_id: BACKEND_URL + "crm/get-customer-by-id/",
  update_customer: BACKEND_URL + "crm/update-customer",
  get_customer_booking: BACKEND_URL + "crm/get-booking-history/",
  add_crm_customers: BACKEND_URL + "crm/store-customer",
  set_email: BACKEND_URL + "crm/send-email",
  set_sms :BACKEND_URL + "crm/send-sms",
  set_wallet: BACKEND_URL + "crm/update-wallet-points",
  crm_subscription_plans: BACKEND_URL + "crm/get-subscription-plan",
  Buy_Wallet:BACKEND_URL +"crm/buy-wallet",
  create_crm_subscription: BACKEND_URL + "crm/subscriptions/create-subscription",
  save_membership_subscription:BACKEND_URL + "crm/membership-subscriptions/create-membership-subscription",
  update_membership_subscription:BACKEND_URL + "crm/membership-subscriptions/update-membership-subscription",
  update_crm_subscription: BACKEND_URL + "crm/subscriptions/update-subscription",
  active_subscription: BACKEND_URL + "crm/subscriptions/active-subscription/",
  get_treatment_form: BACKEND_URL + "get-treatment-forms/",
  submitted_treatment_form: BACKEND_URL + "get-submitted-treatment-forms/",
  get_form_detail: BACKEND_URL + "get-treatment-form-by-slug/",
  store_form_detail: BACKEND_URL + "store-treatment-form-data",
  update_form:BACKEND_URL + "update-treatment-form-data",
  form_link : BACKEND_URL +"crm/send-form-link",
  Subscription_Cancle :BACKEND_URL + "crm/subscriptions/cancel-subscription?user_id=",
  Account_Removal:BACKEND_URL + "customers/cancel-account-request",

  call_logs: BACKEND_URL + "crm/un-confirmed-call-logs",
  activity_log_data:BACKEND_URL + "crm/get-activity-logs",
  Conirmed_Call_Log: BACKEND_URL + "crm/confirmed-call-logs",
  Set_Satisfied: BACKEND_URL + "crm/set-satisfied-call-logs",
  set_confirm_call_logs: BACKEND_URL + "crm/set-confirmed-call-logs",
  notification_alert:BACKEND_URL + "crm/reports/get-notification-alerts",


  /***************************************************** CRM  Staff Attendance ******************************************************/
  get_all_crm_attendance: BACKEND_URL + "crm/attendance/get-attendance?status=",
  update_crm_attendance: BACKEND_URL + "crm/attendance/store-attendance",

  /***************************************************** CRM  Calender *******************************/
  crm_calender_booking: BACKEND_URL + "crm/calender/bookings",

  /***************************************************** CRM  Dashboard *******************************/
  crm_dashboard: BACKEND_URL + "crm/dashboard/booking-overview",
  crm_Memberoverview:BACKEND_URL + "crm/dashboard/membership-overview",
  Daily_reports_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-daily-members-report",
  Weekly_reports_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-weekly-members-report",

  Monthly_reports_dashboard:BACKEND_URL + "crm/dashboard/get-dashboard-monthly-members-report",



  salesHour_reports_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-daily-sales-hours-report",
  salesHour_reports_Weekly: BACKEND_URL + "crm/dashboard/get-dashboard-weekly-sales-hours-report",  
  event_reports: BACKEND_URL + "crm/dashboard/get-dashboard-events-report",
  salesHour_Monthly:BACKEND_URL +"crm/dashboard/get-dashboard-monthly-sales-hours-report", 

  Daily_consultation:BACKEND_URL +"crm/dashboard/get-dashboard-daily-consultation-report",
  weekly_consultation:BACKEND_URL +"crm/dashboard/get-dashboard-weekly-consultation-report",
  Monthly_consultation:BACKEND_URL +"crm/dashboard/get-dashboard-monthly-consultation-report",


  // salesHour_reports_Monthly: BACKEND_URL + "crm/dashboard/get-dashboard-daily-sales-hours-report",
  monthlyinsight_report_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-monthly-insight-report",
  All_Dashboard:BACKEND_URL + "crm/dashboard/get-dashboard-report",
  category_report_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-categories-report",
  consultation_report_dashboard: BACKEND_URL + "crm/dashboard/get-dashboard-consultation-report",
  /***************************************************** reports *******************************/
  general_reports: BACKEND_URL + "crm/reports/general-report",
  sales_reports: BACKEND_URL + "crm/reports/get-sale-report",
  giftCard_report :BACKEND_URL +"crm/reports/get-gift-card-overview",
  gift_Details:BACKEND_URL+"crm/reports/get-gift-card-detail",
  sales_reports_by_type: BACKEND_URL + "crm/reports/sale-report",
  booking_types: BACKEND_URL + "crm/get-booking-types",
  member_reports: BACKEND_URL + "crm/reports/member-report",
  wallet_report: BACKEND_URL + "crm/reports/wallet-report",
  eod_reports: BACKEND_URL + "crm/reports/eod-report",
  staff_sale_reports: BACKEND_URL + "crm/reports/staff-sale-report",
  category_sale_reports: BACKEND_URL + "crm/reports/category-sale-report",
  Wallet_Api:BACKEND_URL + "crm/reports/wallet-report-detail",
  category_report: BACKEND_URL + "crm/reports/get-service-categories",
  Staff_reports: BACKEND_URL + "crm/reports/get-staffs",
  Service_Category: BACKEND_URL + "crm/service-categories/get-all-categories",
  Staff_report: BACKEND_URL + "crm/reports/get-staffs",
  Member_Count: BACKEND_URL + "crm/reports/get-member-report",   
  Attendace_report: BACKEND_URL + "crm/reports/get-attendance-report",
  Member_Booking: BACKEND_URL + "crm/reports/member-report",
  /******************************************************Membership Screen **********************************************/
  get_membership_list:BACKEND_URL +"crm/membership-subscriptions/get-membership-subscriptions",
  get_membership_list_detail:BACKEND_URL +"crm/membership-subscriptions/get-membership-subscription/",
  get_user:BACKEND_URL +"crm/user/get-users",
  cancel_membership:BACKEND_URL +"crm/membership-subscriptions/cancel-membership-subscription",
  pause_membership:BACKEND_URL +"crm/membership-subscriptions/pause-membership-subscription",
  resume_membership:BACKEND_URL +"crm/membership-subscriptions/resume-membership-subscription",
  /***************************************************** CRM  Inventory *******************************/
  get_all_product_category: BACKEND_URL + "crm/product_categories/get-all-product-categories",
  get_all_product_category_by_id: BACKEND_URL + "crm/product_categories/get-product-category-by-id/",
  store_product_category: BACKEND_URL + "crm/product_categories/store-product-category",
  update_product_category: BACKEND_URL + "crm/product_categories/update-product-category",
invoice_details:BACKEND_URL + "crm/pos/get-invoice-by-id/",
   

  get_all_vendors: BACKEND_URL + "crm/vendor/get-all-vendors",
  get_vendors_by_id: BACKEND_URL + "crm/vendor/get-vendor-by-id/",
  store_vendors: BACKEND_URL + "crm/vendor/store-vendor",
  update_vendors: BACKEND_URL + "crm/vendor/update-vendor",

  get_all_brands: BACKEND_URL + "crm/brand/get-all-brands",
  get_brand_by_id: BACKEND_URL + "crm/brand/get-brand-by-id/",
  store_brand: BACKEND_URL + "crm/brand/store-brand",
  update_brand: BACKEND_URL + "crm/brand/update-brand",

  get_all_products: BACKEND_URL + "crm/products/get-all-products",
  get_product_by_id: BACKEND_URL + "crm/products/get-product-by-id/",
  store_product: BACKEND_URL + "crm/products/store-product",
  update_product: BACKEND_URL + "crm/products/update-product",

  get_all_purchase: BACKEND_URL + "crm/inventory-purchase/get-all-purchase",
  get_purchase_by_id: BACKEND_URL + "crm/inventory-purchase/get-purchase-by-id/",
  store_purchase: BACKEND_URL + "crm/inventory-purchase/store-purchase",
  update_purchase: BACKEND_URL + "crm/inventory-purchase/update-purchase",

  get_all_consume_invoice: BACKEND_URL + "crm/inventory-purchase/get-all-purchase-history",
  search_consume_invoice: BACKEND_URL + "crm/inventory-purchase/get-all-purchase-history?start_date=",


  /***************************   customer Notes gallery attachment milestore *******************************/
  get_customer_all_notes: BACKEND_URL + "crm/customer-notes/get-all-notes?customer_id=",
  store_customer_notes: BACKEND_URL + "crm/customer-notes/store-note",
  store_customer_milestone: BACKEND_URL + "crm/customer-attachments/store-milestone",
  get_all_customer_milestone: BACKEND_URL + "crm/customer-attachments/get-milestone/",


  get_all_customer_attachment: BACKEND_URL + "crm/customer-attachments/get-all-attachments?customer_id=",
  delete_customer_attachment: BACKEND_URL + "crm/customer-attachments/delete-attachment/",
  store_customer_attachment: BACKEND_URL + "crm/customer-attachments/store-attachment",

  store_customer_gallery: BACKEND_URL + "crm/customer-attachments/store-gallery-attachment",
  get_all_customer_gallery: BACKEND_URL + "crm/customer-attachments/get-gallery-attachment/",
  delete_customer_gallery: BACKEND_URL + "crm/customer-attachments/delete-gallery/",
  delete_customer_milestone: BACKEND_URL + "crm/customer-attachments/remove-milestone/",
  delete_customer_gallery_file: BACKEND_URL + "crm/customer-attachments/delete-gallery-file/",

  get_all_customer_reminders: BACKEND_URL + "crm/customer-reminders/get-all-customer-reminders?customer_id=",
  store_customer_reminder: BACKEND_URL + "crm/customer-reminders/store-customer-reminder",
  // home api 
  get_clinic:BACKEND_URL +"get-clinics",
  get_giftcards:BACKEND_URL +"get-all-gift-cards?provider_id=",
  get_buygiftcard:BACKEND_URL +"purchase-gift-card",
  Subscription_Plan :BACKEND_URL +"get-subscription-plan?provider_id=",
  All_Services:BACKEND_URL +"get-all-clinic-categories?provider_id=",
  Service_Plan:BACKEND_URL +"get-all-clinic-booking-plan?provider_id=",
  Staff:BACKEND_URL +"get-all-staffs?provider_id=",
  Sub_Category :BACKEND_URL +"get-all-level-three-categories",
  Services:BACKEND_URL +"get-booking-service",
  PromoService:BACKEND_URL +"get-booking-promotion-service?provider_id=",
  milstone_user:BACKEND_URL +"customers/get-milestone/"


}; 

export default apis;
